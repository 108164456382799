/**
 * Hamburger module.
 *
 * @module components/Hamburger
 *
 * @file Hamburger.js
 *
 * Hamburger Functionality
 *
 *
 * @class      Hamburger
 * @package    dove-cottage
 * @subpackage dove-cottage
 * @author     Daryl Bowers <daryl@peapods.co.uk>
 *
 */
import * as focusTrap from 'focus-trap'

export class Hamburger {
  constructor() {

    // Set up focus traps
    this.navTrap = focusTrap.createFocusTrap(['#custom-menu', '#hamburger'], {
      onDeactivate: () => this.closeMenu(),
      initialFocus: false
    })

    // Click handler for hamburger
    document.querySelector('.hamburger').addEventListener('click', () => this.toggleMenu())

    // Click handler for menu links
    document.querySelectorAll('#custom-menu a').forEach(link => {
      link.addEventListener('click', () => this.closeMenu())
    })

    // Click handler for window resize
    window.addEventListener('resize', function () {
      const mediaQuery = window.matchMedia('(min-width: 1025px)')

      // Check if the media query is true
      if (mediaQuery.matches) () => this.closeMenu()
    })
  }

  toggleMenu() {

    // Close the nav menu if open
    if (document.querySelector('#custom-menu').classList.contains('open')) {
      this.closeMenu()
      return
    }

    // Open the nav menu
    this.openMenu()
  }

  openMenu() {

    document.querySelector('#custom-menu').classList.add('open')
    document.querySelector('.hamburger').setAttribute("aria-expanded", "true")
    document.querySelector('#custom-menu').setAttribute("aria-modal", "true")
    document.querySelector('.hamburger--spin').classList.add('is-active')

    // Activate trap after a delay to allow the menu to open
    setTimeout(() => this.navTrap.activate(), 100)
  }

  closeMenu() {
    this.navTrap.deactivate()

    document.querySelector('#custom-menu').classList.remove('open')
    document.querySelector('.hamburger').setAttribute("aria-expanded", "false")
    document.querySelector('#custom-menu').setAttribute("aria-modal", "false")
    document.querySelector('.hamburger--spin').classList.remove('is-active')
    document.querySelector('.hamburger').blur()
  }

}

