/**
 * Init module.
 *
 * @module components/init
 * @see module:main
 *
 * @file init.js
 *
 * Initializes the scripts
 *
 * Imports the javascript modules.
 * Creates new instances of the theme classes.
 *
 * @class      Init
 * @package    dove-cottage
 * @subpackage dove-cottage
 * @author     Daryl Bowers <daryl@peapods.co.uk>
 *
 */

import Common from './common.js'
import { DisableCookies } from './disable-cookies.js'
import { Hamburger } from './hamburger.js'
import { FixHeights } from './fix-heights.js'
import { Map } from './map.js'
import { InView } from './in-view.js'
import { Gallery } from './gallery.js'
import { HomeHero } from './home-hero.js'
import { InputFocus } from './input-focus.js'
import 'altcha'

export class Init {
  constructor() {

    new DisableCookies

    // Initialize common variables
    Common.init()

    // Output initialisation message
    if (Common.consoleLogging) console.log('Initializing Dove Cottage Theme Script')

    new Hamburger
    new FixHeights
    new Map
    new InView
    new Gallery
    new HomeHero
    new InputFocus

    // Add initialized class to body
    document.body.classList.add('initialized')
  }
}