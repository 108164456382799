/**
 * HomeHero module.
 *
 * @module components/HomeHero
 *
 * @file HomeHero.js
 *
 * HomeHero Functionality
 *
 *
 * @class      HomeHero
 * @package    dove-cottage
 * @subpackage dove-cottage
 * @author     Daryl Bowers <daryl@peapods.co.uk>
 *
 */

// import $ from 'jquery'

// export class HomeHero {
//   constructor() {

//     // Return if home-hero id is not present
//     if (!document.getElementById('home-hero')) return

//     // Setup a timer for the hero slider
//     this.intervalTimer = null

//     // Set up the timer
//     this.setTimer()

//     // If the user clicks on the hero container, advance the slide and reset the timer
//     $('#home-hero').on('click', () => {
//       this.changeSlide()
//       this.setTimer()
//     })
//   }

//   setTimer() {
//     // Reset the timer
//     clearInterval(this.intervalTimer)
//     this.intervalTimer = setInterval(this.changeSlide, 7000)
//   }

//   changeSlide() {

//     // Get the data-frame attribute of the hero container
//     // Increase value by 1 and reset to 1 if it exceeds 5
//     let frame = $('#home-hero').attr('data-frame')
//     frame++
//     if (frame > 5) frame = 1

//     // Set the data-frame attribute of the hero container
//     $('#home-hero').attr('data-frame', frame)
//   }
// }


export class HomeHero {

  constructor() {

    // Return if home-hero id is not present
    if (!document.getElementById('home-hero')) return

    // Cache the hero container
    this.hero = document.getElementById('home-hero')

    // Set the initial height of the hero container for portrait devices
    this.setHeroHeight()

    // If the user resizes the window, reset the portrait sise of the hero container
    // Always reset if the width has changed
    // If the width hasn't changed, only reset if the height has changed by more than 50px
    window.addEventListener('resize', () => {
      if (window.innerWidth !== this.pageWidth || this.heightChange() > 50) this.setHeroHeight()
    })

    // Cache the dots
    this.dots = this.hero.querySelectorAll('.dots-layer div')

    // Setup a timer for the hero slider
    this.intervalTimer = null

    // Set up the timer
    this.setTimer()

    // If the user clicks on the hero container, advance the slide and reset the timer
    this.hero.addEventListener('click', () => {
      this.changeSlide()
      this.setTimer()
    })

    // If the user clicks on a dot, advance the slide to the corresponding frame and reset the timer
    // The dot index is the same as the frame number
    this.dots.forEach((dot, index) => {
      dot.addEventListener('click', () => {
        this.hero.setAttribute('data-frame', index)
        this.setTimer()
      })
    })

    // If any of the dots has focus and the user presses enter or space key, 
    // advance the slide to the corresponding frame and reset the timer
    this.dots.forEach((dot, index) => {
      dot.addEventListener('keydown', (event) => {
        if (event.key === 'Enter' || event.key === ' ') {
          this.hero.setAttribute('data-frame', index + 1)
          this.setTimer()

          event.preventDefault()
          return false
        }
      })
    })

    // If any of the dots has focus and the user presses the left arrow key,
    // advance the slide to the previous frame and reset the timer
    // and set focus to the new dot
    this.dots.forEach((dot) => {
      dot.addEventListener('keydown', (event) => {
        if (event.key === 'ArrowLeft') {
          let frame = this.hero.getAttribute('data-frame')
          frame--
          if (frame < 1) frame = 5
          this.hero.setAttribute('data-frame', frame)
          this.setTimer()

          // Set focus to the new dot
          this.dots[frame - 1].focus()

          return false
        }
      })
    })

    // If any of the dots has focus and the user presses the right arrow key,
    // advance the slide to the next frame and reset the timer
    // and set focus to the new dot
    this.dots.forEach((dot) => {
      dot.addEventListener('keydown', (event) => {
        if (event.key === 'ArrowRight') {
          let frame = this.hero.getAttribute('data-frame')
          frame++
          if (frame > 5) frame = 1
          this.hero.setAttribute('data-frame', frame)
          this.setTimer()

          // Set focus to the new dot
          this.dots[frame - 1].focus()

          return false
        }
      })
    })
  }

  setTimer() {
    // Reset the timer
    clearInterval(this.intervalTimer)
    this.intervalTimer = setInterval(() => this.changeSlide(), 7000)
  }

  changeSlide() {

    // Get the data-frame attribute of the hero container
    // Increase value by 1 and reset to 1 if it exceeds 5
    let frame = this.hero.getAttribute('data-frame')
    frame++
    if (frame > 5) frame = 1

    // Set the data-frame attribute of the hero container
    this.hero.setAttribute('data-frame', frame)
  }

  getPageHeight() {
    return window.innerHeight - document.querySelector('header').offsetHeight + 1
  }

  setHeroHeight() {
    // Get the size of the hero container
    this.pageHeight = this.getPageHeight()
    this.pageWidth = window.innerWidth

    // Set the initial height of the hero container for portrait devices
    // Calculated by taking the header height from the window.innerHeight and adding 1px
    document.documentElement.style.setProperty('--hero-height', this.pageHeight + 'px')
  }

  heightChange() {
    return Math.abs(this.pageHeight - this.getPageHeight())
  }
}