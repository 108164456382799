/**
 * Map module.
 *
 * @module components/Map
 *
 * @file Map.js
 *
 * Map Functionality
 *
 *
 * @class      Map
 * @package    dove-cottage
 * @subpackage dove-cottage
 * @author     Daryl Bowers <daryl@peapods.co.uk>
 *
 */

import Common from './common.js'
import $ from 'jquery'

export class Map {
  constructor() {

    // Quit if map element not present
    if ($('#map').length === 0) return

    this.map

    this.token = 'pk.eyJ1IjoicGVhcG9kcyIsImEiOiJja3A0ZnA2d2cwMG1nMnBxZWpxZDluc3V1In0.O0Hce5zs2-mQIQJnoSJeHA'
    this.style = 'mapbox://styles/peapods/ckqno4dnz3fs119lcchvp3l7l'

    this.center = [0.846202, 52.957308]
    this.marker = [0.8462075, 52.957310]
    this.zoom = 15

    // Quit if browser unable to handle map
    if (typeof mapboxgl == 'undefined' || !mapboxgl.supported()) {
      return
    }

    this.setupMap()
  }

  setupMap() {

    mapboxgl.accessToken = this.token

    this.map = new mapboxgl.Map({
      container: 'map',
      style: this.style,
      center: this.center,
      zoom: this.zoom,
      // attributionControl: false
    })

    // Add zoom and rotation controls to the map.
    this.map.addControl(new mapboxgl.NavigationControl())

    this.addMarker()

    this.touchEvents()
  }

  addMarker() {

    let marker1 = new mapboxgl.Marker({ 'color': '#4a819f' })
      .setLngLat(this.marker)
      .addTo(this.map)
  }

  touchEvents() {

    // Disable scroll wheel zoom
    this.map.scrollZoom.disable()

    this.map.dragRotate.disable()
    this.map.touchPitch.disable()
    this.map.touchZoomRotate.disable()

    this.map.on('dragstart', (event) => {

      if (this.isTouchEvent(event) && !this.isTwoFingerTouch(event)) {

        this.map.dragPan.disable()
        this.map.touchZoomRotate.disable()
      }
    })

    // Drag events not emited after dragPan disabled, so I use touch event here
    this.map.on('touchstart', (event) => {

      if (this.isTouchEvent(event) && this.isTwoFingerTouch(event)) {

        this.map.dragPan.enable()
        this.map.touchZoomRotate.enable()
        this.map.touchZoomRotate.disableRotation()
      }
    })
  }

  isTouchEvent(e) {
    return e.originalEvent && 'touches' in e.originalEvent
  };

  isTwoFingerTouch(e) {
    return e.originalEvent.touches.length >= 2
  };
}