/**
 * Disable Cookies module.
 *
 * @module components/disable-cookies
 *
 * @file disable-cookies.js
 *
 * Disable Cookies Functionality
 *
 *
 * @class      Disable Cookies
 * @package    dove-cottage
 * @subpackage dove-cottage
 * @author     Daryl Bowers <daryl@peapods.co.uk>
 *
 */

import Common from './common.js';
import $ from 'jquery';

export class DisableCookies {
  constructor() {

    if (!document.__defineGetter__) {
      Object.defineProperty(document, 'cookie', {
        get: function() { return ''; },
        set: function() { return true; },
      });
    } else {
      document.__defineGetter__('cookie', function() { return ''; });
      document.__defineSetter__('cookie', function() {});
    }

  }
}