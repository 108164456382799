/**
 * Gallery module.
 *
 * @module components/Gallery
 *
 * @file Gallery.js
 *
 * Gallery Functionality
 *
 *
 * @class      Gallery
 * @package    dove-cottage
 * @subpackage dove-cottage
 * @author     Daryl Bowers <daryl@peapods.co.uk>
 *
 */

import Common from './common.js'
import $ from 'jquery'

export class Gallery {
  constructor() {

    // Current image size
    this.imgSize

    // Masonry grid element
    this.$grid = $('#gallery-grid')

    // Cache DOM elements
    this.$images = this.$grid.find('a')
    this.$zoom = $('#gallery-zoom')
    this.$overlay = $('#window-overlay')

    // Click handlers to remove overlay and image
    this.$zoom.on('click', () => this.removeZoom())
    this.$overlay.on('click', () => this.removeZoom())

    // Click handler for window resize
    this.mediaQuery = window.matchMedia('(min-width: 769px)')

    // Click handler for images
    this.$images.on('click tap', (event) => this.triggerZoom(event))

    // Window resize event listener
    window.addEventListener('resize', () => this.resizeOverlay())

    // Check keyboard events
    window.addEventListener('keydown', (event) => this.checkKey(event))
  }

  checkKey(event) {
    if (event.key === 'Escape') {
      this.removeZoom()
    }
  }

  triggerZoom(event) {

    event.preventDefault()

    if (!this.mediaQuery.matches) return false

    let $image = $(event.target)

    // Copy the $image element into the zoom window
    this.$zoom.html($image.clone())

    this.setZoomPosition()

    this.$overlay.addClass('active')
    this.$zoom.addClass('active')
  }

  removeZoom() {

    if (!this.$zoom.hasClass('active')) return

    this.$overlay.removeClass('active')
    this.$zoom.removeClass('active')
  }

  resizeOverlay() {
    if (this.$zoom.hasClass('active')) {
      this.setZoomPosition()
    }
  }

  setZoomPosition() {

    // Calculate the image width
    let zoomWidth = window.innerWidth * 0.9
    zoomWidth = (zoomWidth > 1600) ? 1600 : zoomWidth

    // Calculate the image height @ 3x2 ratio
    let zoomHeight = (zoomWidth / 3) * 2

    // Correct dimensions if too tall
    if (zoomHeight > (window.innerHeight * 0.9)) {
      zoomHeight = window.innerHeight * 0.9
      zoomWidth = (zoomHeight / 2) * 3
    }

    // Calculate the left position
    let zoomLeft = (window.innerWidth / 2) - (zoomWidth / 2)

    // Calculate the top position
    let zoomTop = (window.innerHeight / 2) - (zoomHeight / 2)

    // Set the zoom position
    this.$zoom.css({
      'top': zoomTop + 'px',
      'left': zoomLeft + 'px',
      'width': zoomWidth + 'px',
      'height': zoomHeight + 'px'
    })
  }
}