/**
 * Dove Cottage javascript module.
 *
 * @module main
 *
 * @file main.js
 *
 * Initiates the necessary javascript
 *
 * Imports the Init module and runs the plugin.
 *
 * @class      DoveCottage
 * @package    dove-cottage
 * @subpackage dove-cottage
 * @author     Daryl Bowers <daryl@peapods.co.uk>
 *
 */
import { Init } from './components/init.js';

class DoveCottage {
  constructor() {
    let init = new Init();
  }
}
window.addEventListener('load', function(event) {
  const doveCottage = new DoveCottage();
});